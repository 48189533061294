import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'

import config from '../utils/siteConfig'
import Layout from '../components/Layout'
import SimpleHero from '../components/SimpleHero'
import Container from '../components/Container'
import PageBody from '../components/PageBody'
import TopicList from '../components/TopicList'
import PostLinks from '../components/PostLinks'
import PostDate from '../components/PostDate'
import SEO from '../components/SEO'

const ShareBlocks = styled.div`
  padding: 40px;
  border: solid 2px ${(props) => props.theme.color.lightGrey};
  margin-top: 60px;

  h4 {
    text-align: center;
  }

  ul {
    margin-top: 60px;
    list-style: none;
    display: flex;
    justify-content: space-around;

    li {
      list-style: none;
    }

    a {
      width: 50px;
      height: 50px;
      border-radius: 50px;
      background: transparent;
      border: 1px solid #000;
      color: #000;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        transition: all 0.15s linear;
      }

      &.facebook {
        border-color: ${(props) => props.theme.socialColor.facebook};
      }
      &.facebook:hover {
        background-color: ${(props) => props.theme.socialColor.facebook};
      }
      &.facebook i {
        color: ${(props) => props.theme.socialColor.facebook};
      }

      &.pinterest {
        border-color: ${(props) => props.theme.socialColor.pinterest};
      }
      &.pinterest:hover {
        background-color: ${(props) => props.theme.socialColor.pinterest};
      }
      &.pinterest i {
        color: ${(props) => props.theme.socialColor.pinterest};
      }

      &.twitter {
        border-color: ${(props) => props.theme.socialColor.twitter};
      }
      &.twitter:hover {
        background-color: ${(props) => props.theme.socialColor.twitter};
      }
      &.twitter i {
        color: ${(props) => props.theme.socialColor.twitter};
      }

      &.email {
        border-color: rgba(0, 0, 0, 0.84);
      }
      &.email:hover {
        background-color: rgba(0, 0, 0, 0.84);
      }
      &.email i {
        color: rgba(0, 0, 0, 0.84);
      }

      &:hover i {
        color: #fff;
      }
    }
  }
`

const PostTemplate = ({ data, pageContext }) => {
  const { title, slug, heroImage, body, publishDate, topics } =
    data.contentfulPost
  const postNode = data.contentfulPost
  const previous = pageContext.prev
  const next = pageContext.next
  const seo = {
    pageTitle: title,
    shareImage: {
      ogimg: {
        src: heroImage.ogimg.src,
        width: heroImage.ogimg.width,
        height: heroImage.ogimg.height,
      },
    },
    metaDescription: {
      metaDescription: body.childMarkdownRemark.excerpt,
    },
    metaKeywords: {
      metaKeywords: `KPC Architectural Products, Inc., ${title}`,
    },
  }

  return (
    <Layout>
      <Helmet>
        <title>{`${title} - ${config.siteTitle}`}</title>
      </Helmet>
      {seo && (
        <SEO pagePath={`blog/${pageContext.slug}`} postNode={seo} postSEO />
      )}
      <SimpleHero headline={title} secondaryURL="/blog/" secondaryName="Blog" />

      <div className="blog-area blog-details white-bg pb-60 clearfix">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 mobi-mb-50">
              <div className="blog-post clearfix pr-20">
                <div className="thumb mb-60">
                  <Img fluid={heroImage.fluid} />
                </div>
                <div className="blog-text plr-50 mb-70">
                  <h6 className="mb-15">Posted On: {publishDate}</h6>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: body.childMarkdownRemark.html,
                    }}
                  />
                  {topics && (
                    <div className="tag mt-50">
                      <strong>Topic{topics.length > 1 ? 's' : ''}:</strong>
                      {topics.map((topic, i) => {
                        return (
                          <span key={topic.id}>
                            <Link to={`/blog/topic/${topic.slug}`}>
                              {topic.title}
                            </Link>
                            {topics.length === i + 1 ? '' : <>,&nbsp;</>}
                          </span>
                        )
                      })}

                      <ShareBlocks>
                        <h4 className="header">Share this post</h4>
                        <ul>
                          <li>
                            <a
                              href="http://www.facebook.com/sharer.php?u=http://blog.dixiebellepaint.com/blog-post/diy-woodland-nursery-art-traceys-fancy/&amp;t=DIY Woodland Nursery Art – Tracey’s Fancy"
                              className="facebook"
                              title="Facebook"
                            >
                              <i
                                className="fab fa-facebook-f"
                                aria-hidden="true"
                              />
                            </a>
                          </li>
                          <li>
                            <a
                              href="http://pinterest.com/pin/create/button/?url=http%3A%2F%2Fblog.dixiebellepaint.com%2Fblog-post%2Fdiy-woodland-nursery-art-traceys-fancy%2F&amp;media=http%3A%2F%2Fblog.dixiebellepaint.com%2Fwp-content%2Fuploads%2F2018%2F12%2F1546089035_nurser222236.png"
                              className="pinterest"
                              title="Pinterest"
                            >
                              <i
                                className="fab fa-pinterest"
                                aria-hidden="true"
                              />
                            </a>
                          </li>
                          <li>
                            <a
                              href="http://twitter.com/share?text=DIY+Woodland+Nursery+Art+%26%238211%3B+Tracey%26%238217%3Bs+Fancy&amp;url=http%3A%2F%2Fblog.dixiebellepaint.com%2Fblog-post%2Fdiy-woodland-nursery-art-traceys-fancy%2F"
                              className="twitter"
                              title="Twitter"
                            >
                              <i
                                className="fab fa-twitter"
                                aria-hidden="true"
                              />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:type%20email%20address%20here?subject=I%20wanted%20to%20share%20this%20post%20with%20you%20from%20Dixie Belle Paint Company&amp;body=DIY Woodland Nursery Art – Tracey’s Fancy - http://blog.dixiebellepaint.com/blog-post/diy-woodland-nursery-art-traceys-fancy/"
                              className="email"
                              title="Email"
                            >
                              <i
                                className="fa fa-envelope-open-text"
                                aria-hidden="true"
                              />
                            </a>
                          </li>
                        </ul>
                      </ShareBlocks>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PostLinks pagePath="blog" previous={previous} next={next} />
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      title
      slug
      publishDate(formatString: "MMMM DD, YYYY")
      publishDateISO: publishDate(formatString: "YYYY-MM-DD")
      topics {
        title
        id
        slug
      }
      heroImage {
        title
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_noBase64
        }
        ogimg: resize(width: 1800) {
          src
          width
          height
        }
      }
      body {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 320)
        }
      }
    }
  }
`

export default PostTemplate
