import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  /* justify-content: space-between; */
  /* width: 100%; */
`

const PreviousLink = styled(Link)`
  margin-right: auto;
  order: 1;
`

const NextLink = styled(Link)`
  margin-left: auto;
  order: 3;
`

const PageIndicator = styled.span`
  color: gray;
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
  padding: 1em 1.5em;
  z-index: -1;
  opacity: 0.7;
`

const PostLinks = props => {
  return (
     <div className="blog-area style2 white-bg pt-40 mb-60 clearfix">
      <div className="container">
        <div className="row">
          <Wrapper className="col-xs-12 col-sm-8 col-md-9 col-text-center mobi-mb-50">
              {props.previous && (
                <PreviousLink className="btn theme-btn mt-20" to={`/${props.pagePath}/${props.previous.slug}/`}>
                  &#8592; Prev {props.isProject ? "Project" : "Post"}
                </PreviousLink>
              )}
              {props.next && (
                <NextLink className="btn theme-btn mt-20" to={`/${props.pagePath}/${props.next.slug}/`}>Next {props.isProject ? "Project" : "Post"} &#8594;</NextLink>
              )}
          </Wrapper>
        </div>
      </div>
    </div>
  )
}

export default PostLinks
